import { ErrorMessage, Field, useFormikContext, useFormik } from 'formik';
import styled from 'styled-components';
import React from 'react';
import Card from 'react-bootstrap/Card';
import CH_Assembly from '../../images/CH_Assembly.svg';
import CH_Painting from '../../images/CH_Painting.svg';
import CH_Pro from '../../images/CH_Pro.svg';
import CH_Projects from '../../images/CH_Projects.svg';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CheckCircleFill, CheckCircle } from 'react-bootstrap-icons';
const DivisionsStyled = styled.div`
	.divisionCard {
		width: 16rem;
		min-height: 28em;
		-webkit-box-shadow: 5px 5px 5px 2px rgba(115, 115, 115, 0.57);
		box-shadow: 5px 5px 5px 2px rgba(115, 115, 115, 0.57);
	}
	.divisionCard:hover {
		-webkit-box-shadow: 0px 10px 13px -7px #565656,
			5px 5px 15px 5px rgb(115 115 115 / 57%);
		box-shadow: 0px 10px 13px -7px #565656,
			5px 5px 15px 5px rgb(115 115 115 / 57%);
		cursor: pointer;
	}

	.selected {
		background: #ff786e17;
		box-shadow: 5px 5px 5px 2px rgb(179 141 141 / 57%);
	}
	.selected:hover {
		-webkit-box-shadow: 0px 10px 13px -7px rgb(255 174 174 / 57%),
			5px 5px 15px 5px rgb(255 174 174 / 57%);
		box-shadow: 0px 10px 13px -7px rgb(255 174 174 / 57%),
			5px 5px 15px 5px rgb(255 174 174 / 57%) !important;
	}

	.formTitle {
		text-align: center;
		padding-top: 1em;
		color: #e93644;
		font-weight: 700;
		font-size: var(--font-size-md);
	}
	.error {
		color: red;
	}
	.priceText {
		bottom: 135px;
	}

	.cardsDiv {
		margin: 2em 4em;
		text-align: -webkit-center;
	}
	// small screen settings
	@media (max-width: 1368px) {
		.divisionCard {
			width: 17rem;
		}
		.cardsDiv {
			margin: 2em 5em;
		}
	}
	// mobile settings
	@media (max-width: 768px) {
		.divisionCard {
			min-height: 30em;
			margin-bottom: 1em;
		}
		.cardsDiv {
			margin: 2em 0em;
		}
	}
`;

const CardComponent = (props) => {
	return (
		<Card
			component='input'
			id={props.id}
			name={props.name}
			value={props.value}
			onClick={props.onClick}
			className={`divisionCard ${props.selected ? 'selected' : ''}`}
		>
			<Card.Img height={200} variant='top' src={props.imgSrc} />
			<Card.Body>
				<Card.Title>{props.title}</Card.Title>
				<Card.Subtitle className='position-absolute start-50 translate-middle-x text-muted priceText'>
					{props.subtitle}
				</Card.Subtitle>
				<div
					style={{ bottom: '60px' }}
					className='position-absolute start-50 translate-middle-x mb-3'
				>
					{props.selected ? (
						<CheckCircleFill color='red' size={45} />
					) : (
						<CheckCircle color='red' size={45} />
					)}
				</div>
				<div
					className='position-absolute bottom-0 start-50 translate-middle-x mb-3'
					style={{ color: '#e93644' }}
				>
					Click to select
				</div>
			</Card.Body>
		</Card>
	);
};

export default function Divisions(props) {
	return (
		<DivisionsStyled>
			<h5 className='formTitle'>How can we help you?</h5>
			<div>Service Callout fee of $88 inc GST applies</div>
			<div className='cardsDiv'>
				<Row
					xs={1}
					md={2}
					lg={2}
					xl={4}
					// className='g-3'
				>
					<Col className='mb-2'>
						{/* <button type='button' class='btn btn-danger mb-2 position-relative'>
							Most Popular{' '}
							<svg
								width='1em'
								height='1em'
								viewBox='0 0 16 16'
								class='position-absolute top-100 start-50 translate-middle mt-1 bi bi-caret-down-fill'
								fill='red'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
							</svg>
						</button> */}
						<CardComponent
							id='division'
							name='division'
							value={props.division}
							onClick={() => props.setDivision('City Handyman Pro - $88/hr')}
							selected={props.division === 'City Handyman Pro - $88/hr'}
							imgSrc={CH_Pro}
							title='Your home, business, & real estate, handyman all-rounder'
							subtitle='($88/hr)'
						/>
					</Col>
					<Col className='mb-2'>
						<CardComponent
							id='division'
							name='division'
							value={props.division}
							onClick={() =>
								props.setDivision('City Handyman Painting - $88/hr')
							}
							selected={props.division === 'City Handyman Painting - $88/hr'}
							imgSrc={CH_Painting}
							title='It’s all about painting. From small patches, to whole homes'
							subtitle='($88/hr)'
						/>
					</Col>
					<Col className='mb-2'>
						<CardComponent
							id='division'
							name='division'
							value={props.division}
							onClick={() =>
								props.setDivision('City Handyman Assembly - $66/hr')
							}
							selected={props.division === 'City Handyman Assembly - $66/hr'}
							imgSrc={CH_Assembly}
							title="We'll put flat-packs and more together for you"
							subtitle='($66/hr)'
						/>
					</Col>

					<Col className='mb-2'>
						<CardComponent
							id='division'
							name='division'
							value={props.division}
							onClick={() => props.setDivision('City Handyman Big Projects')}
							selected={props.division === 'City Handyman Big Projects'}
							imgSrc={CH_Projects}
							title='Get the help you need on the bigger jobs'
							subtitle='($88/hr)'
						/>
					</Col>
				</Row>
			</div>
		</DivisionsStyled>
	);
}
